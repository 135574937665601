body {
  margin: 0;
  font-family: 'Lucida Grande', 'Lucida Sans Unicode', Arial, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  @media (max-width: 799px) {
    font-size: 0.5em;
  }
}

@media (max-width: 799px) {
  body{
    font-size: 0.8em;
  }
}

#root { height:100%; }

html { height: 100%; }

@font-face {
  font-family: 'seagull';
  src: local('seagull'), url(./assets/fonts/seagull.ttf) format('truetype');
}